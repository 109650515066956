import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import MR from '../pdfs/MefalRaoy.pdf';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf';
import { uploadPdfAndGetUrl } from '../components/firebase'; // Import the upload function

const reverseNumbersOnly = (str) => {
  return str.replace(/\d+/g, (match) => match.split('').reverse().join(''));
};

const generateMefalRaoy = async (formData) => {
  const existingPdfUrl = MR;
  const existingPdfBytes = await fetch(existingPdfUrl).then(res => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Register fontkit to enable custom font embedding
  pdfDoc.registerFontkit(fontkit);

  // Load a font that supports Hebrew characters
  const fontUrl = open_sans_font;
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const hebrewFont = await pdfDoc.embedFont(fontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  // rangeID_currentYear
  const rangeId = formData.rangeId + '_' + formData.registrationDate.split(',')[0].split('.')[2];
  const date = formData.registrationDate;

  const fullName = formData.fullName || 'שם משתמש';
  const teudatZehut = formData.teudatZehut || '123456789';
  const phone = formData.phoneNumber || '123456789';
  const imprintOnPistol = formData.imprintOnPistol || '123456789';
  const menufacturer = formData.pistolManufacturer || '';
  const origin = formData.origin || '';
  //   const model = formData.model || '';
  const caliber = formData.ammunitionCaliber || '9mm';
  const quantity = formData.bulletCount || '50';

  firstPage.drawText(rangeId, { x: 130, y: 800, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(date, { x: 130, y: 662, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(fullName, { x: 440, y: 614, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(teudatZehut, { x: 300, y: 614, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(phone, { x: 130, y: 614, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(menufacturer, { x: 300, y: 525, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(origin, { x: 130, y: 525, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  //   firstPage.drawText(model, { x: 90, y: 525, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(imprintOnPistol, { x: 440, y: 475, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(caliber, { x: 300, y: 475, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(quantity.toString(), { x: 130, y: 475, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });

  if (formData.managedBy === 'שי לוי') {
    firstPage.drawText(reverseNumbersOnly('שי לוי ת.ז 031875073'), { x: 400, y: 107, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(reverseNumbersOnly('מדריך ירי מס 1000486677'), { x: 400, y: 95, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(reverseNumbersOnly('מנהל מטווח מס 048900029'), { x: 400, y: 83, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  } else if (formData.managedBy === 'טל ביאלוסטוצקי') {
    firstPage.drawText(reverseNumbersOnly('טל ביאלוסטוצקי ת.ז. 302552864'), { x: 400, y: 118, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(reverseNumbersOnly('מפקחת מטווח מס 1200297136'), { x: 400, y: 95, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(reverseNumbersOnly('מ.מ מנהל מטווח מס 048900029'), { x: 400, y: 83, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  }

  if (formData.instructor === 'שי לוי') {
    firstPage.drawText(reverseNumbersOnly('שי לוי ת.ז 031875073'), { x: 90, y: 107, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(reverseNumbersOnly('מדריך ירי מס 1000486677'), { x: 90, y: 95, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(reverseNumbersOnly('מנהל מטווח מס 048900029'), { x: 90, y: 83, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  } else if (formData.instructor === 'אלכס אודיניץ') {
    firstPage.drawText(reverseNumbersOnly('אלכס אודיניץ ת.ז 317864197'), { x: 90, y: 95, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(reverseNumbersOnly('מדריך ירי מס 1200378767'), { x: 90, y: 83, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  } else if (formData.instructor === 'בן אברהם') {
    firstPage.drawText(reverseNumbersOnly('בן דניאל אברהם ת.ז 206158842'), { x: 90, y: 95, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(reverseNumbersOnly('מפקח מטווח מס 1200366653'), { x: 90, y: 83, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
  }

  const pdfBytes = await pdfDoc.save();
  const downloadUrl = await uploadPdfAndGetUrl(pdfBytes, 'Mefal_Raoy', `Mefal_Raoy_${teudatZehut}_${rangeId}.pdf`);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = `Mefal_Raoy_${teudatZehut}_${rangeId}.pdf`;
  link.click();
  return downloadUrl;
};

export default generateMefalRaoy;
