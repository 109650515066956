import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import generateGunSale from '../PDFFileGenerators/GunSaleGen'; // מכירת אקדח
import generateAmmoSale from '../PDFFileGenerators/AmmoGen'; // מכירת תחמושת
import generateGunAmmoSale from '../PDFFileGenerators/gunAndAmmoGen'; // מכירת אקדח ותחמושת
import generateCertificate from '../PDFFileGenerators/LocalTrainCertificateGen'; // אישור הדרכה במטווח
import generateGunPurchase from '../PDFFileGenerators/GunPurchaseGen.js'; // רכישת אקדח
import generateMefalRaoy from '../PDFFileGenerators/MRGen'; // מפעל ראוי
import generatePairingTrain from '../PDFFileGenerators/PairingTrainGen'; // Pairing Train
import '../styles/GunAmmoTrain.css';
import { logError, savePdfUrlToRegistration } from '../components/firebase.js';

const GunAmmoTrain = ({
  formData,
  setFormData,
  activeCase, // The case number (1 to 5) passed as a prop
  setActiveCase,
}) => {
  const sigCanvas = useRef({});
  const [caliber, setCaliber] = useState(formData.ammunitionCaliber || formData.caliber || '');
  const [manufacturer, setManufacturer] = useState(formData.pistolManufacturer || formData.manufacturer || '');
  const [imprintOnPistol, setImprintOnPistol] = useState(formData.imprintOnPistol || '');
  const [pistolModel, setPistolModel] = useState(formData.pistolModel || '');
  const [origin, setOrigin] = useState(formData.origin || '');
  const [hits, setHits] = useState(formData.hits || 0);
  const [gunDisposition, setGunDisposition] = useState('');
  const [ammoQuantity, setAmmoQuantity] = useState(50);
  const [isLoading, setIsLoading] = useState(false);
  const [managedBy, setManagedBy] = useState(formData.managedBy || 'בחר אפשרות');
  const [instructor, setInstructor] = useState(formData.instructor || 'בחר אפשרות');
  const clearSignature = () => sigCanvas.current.clear();

  const handleGenerate = async () => {
    setIsLoading(true);
    let signatureUrl = sigCanvas.current?.toDataURL ? sigCanvas.current.toDataURL('image/png') : null;

    const updatedFormData = {
      ...formData,
      caliber,
      manufacturer,
      pistolModel,
      origin,
      imprintOnPistol,
      signatureUrl,
      ammoQuantity,
      gunDisposition,
      hits,
      managedBy,
      instructor,
    };

    let pdfUrl;
    console.log('activeCase:', activeCase);
    try {
      switch (activeCase) {
        case 1: // Gun Sale
          if (ammoQuantity === '') {
            alert('יש להזין כמות תחמושת');
            setIsLoading(false);
            return;
          }
          pdfUrl = await generateGunSale(updatedFormData);
          setFormData({ ...formData, gunSalePdf: pdfUrl, origin });
          if (formData.dateKey && formData.rangeId) {
            savePdfUrlToRegistration(formData.dateKey, formData.rangeId, 'gunSalePdf', pdfUrl);
          }
          break;
        case 2: // Gun and Ammo Sale
          if (ammoQuantity === '') {
            alert('יש להזין כמות תחמושת');
            setIsLoading(false);
            return;
          }
          pdfUrl = await generateGunAmmoSale(updatedFormData);
          setFormData({ ...formData, gunAmmoSalePdf: pdfUrl, origin });
          if (formData.dateKey && formData.rangeId) {
            savePdfUrlToRegistration(formData.dateKey, formData.rangeId, 'gunAmmoSalePdf', pdfUrl);
          }
          break;
        case 3: // Ammo Sale
          if (ammoQuantity === '') {
            alert('יש להזין כמות תחמושת');
            setIsLoading(false);
            return;
          }
          pdfUrl = await generateAmmoSale(updatedFormData);
          setFormData({ ...formData, AmmoSalePdf: pdfUrl, origin });
          if (formData.dateKey && formData.rangeId) {
            savePdfUrlToRegistration(formData.dateKey, formData.rangeId, 'AmmoSalePdf', pdfUrl);
          }
          break;
        case 4: // Training Certificate
          delete updatedFormData.signatureUrl; // No signature needed
          pdfUrl = await generateCertificate(updatedFormData);
          setFormData({ ...formData, trainCertificatePdf: pdfUrl, origin });
          if (formData.dateKey && formData.rangeId) {
            savePdfUrlToRegistration(formData.dateKey, formData.rangeId, 'trainCertificatePdf', pdfUrl);
          }
          break;
        case 5: // Gun Purchase
          if (ammoQuantity === '') {
            alert('יש להזין כמות תחמושת');
            setIsLoading(false);
            return;
          }
          if (gunDisposition === '') {
            alert('יש לבחור אם הלקוח מסר או מכר את האקדח');
            setIsLoading(false);
            return;
          }
          pdfUrl = await generateGunPurchase(updatedFormData);
          setFormData({ ...formData, gunPurchasePdf: pdfUrl, origin });
          if (formData.dateKey && formData.rangeId) {
            savePdfUrlToRegistration(formData.dateKey, formData.rangeId, 'gunPurchasePdf', pdfUrl);
          }
          break;
        case 6: // Mefal Raoy
          if (ammoQuantity === '') {
            alert('יש להזין כמות תחמושת');
            setIsLoading(false);
            return;
          }
          pdfUrl = await generateMefalRaoy(updatedFormData);
          setFormData({ ...formData, mefalRaoyPdf: pdfUrl, origin });
          if (formData.dateKey && formData.rangeId) {
            savePdfUrlToRegistration(formData.dateKey, formData.rangeId, 'mefalRaoyPdf', pdfUrl);
          }
          break;
        case 7: // Pairing Train
          delete updatedFormData.signatureUrl; // No signature needed
          pdfUrl = await generatePairingTrain(updatedFormData);
          setFormData({ ...formData, pairingTrainPdf: pdfUrl, origin });
          if (formData.dateKey && formData.rangeId) {
            savePdfUrlToRegistration(formData.dateKey, formData.rangeId, 'pairingTrainPdf', pdfUrl);
          }
          break;

        default:
          throw new Error('Invalid case selected.');
          
      }
    } catch (error) {
      logError(error, formData.teudatZehut, `GunAmmoTrain.js - handleGenerate`);
      console.error('Error generating PDF:', error);
    } finally {
      setIsLoading(false);
    }
    setActiveCase(null); // Close the form after submission
  };

  const renderFormFields = () => {
    switch (activeCase) {
      case 1: // Gun Sale
      case 2: // Gun and Ammo Sale
      case 5: // Gun Purchase
      case 6: // Mefal Raoy
        return (
          <>
            <label>
              יצרן:
              <input type="text" value={manufacturer} onChange={(e) => setManufacturer(e.target.value)} />
            </label>
            <label>
              דגם - באנגלית בלבד:
              <input type="text" value={pistolModel} onChange={(e) => setPistolModel(e.target.value)} />
            </label>
            <label>
              ארץ ייצור - בעברית בלבד:
              <input type="text" value={origin} onChange={(e) => setOrigin(e.target.value)} />
            </label>
            <label>
              מספר כלי:
              <input type="text" value={imprintOnPistol} onChange={(e) => setImprintOnPistol(e.target.value)} />
            </label>
            <label>
              קוטר קליע:
              <input type="text" value={caliber} onChange={(e) => setCaliber(e.target.value)} />
            </label>
            {(activeCase === 2 || activeCase === 6) && (
              <label>
                כמות תחמושת:
                <input type="number" value={ammoQuantity} onChange={(e) => setAmmoQuantity(e.target.value)} />
              </label>
            )}
            {activeCase === 5 && (
              <>
              <label>
              הלקוח מסר תחמושת בכמות של :
              <input type="number" value={ammoQuantity} onChange={(e) => setAmmoQuantity(e.target.value)} />
            </label>
              <label>
                הלקוח:
                <select value={gunDisposition} onChange={(e) => setGunDisposition(e.target.value)}>
                  <option value="">בחר</option>
                  <option value="gave">ויתר</option>
                  <option value="sold">מכר</option>
                </select>
              </label>
            </>
            )}
            {activeCase === 6 && (
              <>
             <label>
             מנהל המטווח החתום על הטופס:
             <select value={managedBy} onChange={(e) => setManagedBy(e.target.value)}>
              <option value="בחר מנהל מטווח">בחר מנהל מטווח</option>
               <option value="טל ביאלוסטוצקי">טל ביאלוסטוצקי</option>
               <option value="שי לוי">שי לוי</option>
               <option value="ריק">ללא חתימה</option>
               
             </select>
           </label>
           <label>
             מדריך ירי החתום על הטופס:
             <select value={instructor} onChange={(e) => setInstructor(e.target.value)}>
                <option value="בחר מדריך ירי">בחר מדריך ירי</option>
               <option value="אלכס אודיניץ">אלכסנדר אודיניץ</option>
               <option value="שי לוי">שי לוי</option>
               <option value="בן אברהם">בן אברהם</option>
               <option value="ריק">ללא חתימה</option>
               {/* <option value="יוסף זולדן">ישי זולדן</option>
               <option value="אלכס פינקוביסקי">אלכסנדר פינקוביסקי</option>
               <option value="לימור גל">לימור גל</option>
               <option value="בן אברהם">בן אברהם</option>
               <option value="סורין לייזרוביץ">סורין לייזרוביץ</option>
               <option value="זהר שמעון בכר">זהר שמעון בכר</option>
               <option value="אלי ספרן">אלי ספרן</option>
               <option value="שלמה מנחם ויקסלבאום">שלמה מנחם ויקסלבאום</option>
               <option value="אסף גל">אסף גל</option>
               <option value="גדליהו קינד">גדליהו קינד</option>
               <option value="יוסי אדיב">יוסי אדיב</option>
               <option value="אליהו אסולין">אליהו אסולין</option>
               <option value="עידו מנדל">עידו מנדל</option>
               <option value="דן רפלקסי">דן רפלקסי</option> */}
             </select>
           </label>
           </>  
            )}
          </>
        );
      case 3: // Ammo Sale
        return (
          <>
            <label>
              קוטר קליע:
              <input type="text" value={caliber} onChange={(e) => setCaliber(e.target.value)} />
            </label>
            <label>
              כמות תחמושת:
              <input type="number" value={ammoQuantity} onChange={(e) => setAmmoQuantity(e.target.value)} />
            </label>
          </>
        );
      case 4: // Training Certificate
      case 7: // Pairing Train
        return (
          <>
          <label>
              ארץ ייצור - בעברית בלבד:
              <input type="text" value={origin} onChange={(e) => setOrigin(e.target.value)} />
            </label>
            <label>
              יצרן - באנגלית בלבד:
              <input type="text" value={manufacturer} onChange={(e) => setManufacturer(e.target.value)} />
            </label>
            <label>
              דגם - באנגלית בלבד:
              <input type="text" value={pistolModel} onChange={(e) => setPistolModel(e.target.value)} />
            </label>
            <label>
              קוטר קליע:
              <input type="text" value={caliber} onChange={(e) => setCaliber(e.target.value)} />
            </label>
            
            <label>
              כמות פגיעות מתוך 20 כדורים:
              <input type="number" value={hits} onChange={(e) => setHits(e.target.value)} />
            </label>
            <label>
              מנהל המטווח החתום על הטופס:
              <select value={managedBy} onChange={(e) => setManagedBy(e.target.value)}>
                <option value="בחר מנהל מטווח">בחר מנהל מטווח</option>
                <option value="טל ביאלוסטוצקי">טל ביאלוסטוצקי</option>
                <option value="שי לוי">שי לוי</option>
                <option value="ריק">ללא חתימה</option>
                
              </select>
            </label>
            <label>
              מדריך ירי החתום על הטופס:
              <select value={instructor} onChange={(e) => setInstructor(e.target.value)}>
                <option value="בחר מדריך ירי">בחר מדריך ירי</option>
                <option value="אלכס אודיניץ">אלכסנדר אודיניץ</option>
                <option value="שי לוי">שי לוי</option>
                <option value="בן אברהם">בן אברהם</option>
                <option value="ריק" >ללא חתימה</option>
                {/* <option value="יוסף זולדן">ישי זולדן</option>
                <option value="אלכס פינקוביסקי">אלכסנדר פינקוביסקי</option>
                <option value="לימור גל">לימור גל</option>
                <option value="בן אברהם">בן אברהם</option>
                <option value="סורין לייזרוביץ">סורין לייזרוביץ</option>
                <option value="זהר שמעון בכר">זהר שמעון בכר</option>
                <option value="אלי ספרן">אלי ספרן</option>
                <option value="שלמה מנחם ויקסלבאום">שלמה מנחם ויקסלבאום</option>
                <option value="אסף גל">אסף גל</option>
                <option value="גדליהו קינד">גדליהו קינד</option>
                <option value="יוסי אדיב">יוסי אדיב</option>
                <option value="אליהו אסולין">אליהו אסולין</option>
                <option value="עידו מנדל">עידו מנדל</option>
                <option value="דן רפלקסי">דן רפלקסי</option> */}
              </select>
            </label>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="gun-ammo-train-detail">
      {isLoading ? (
        <div className="gun-ammo-train-loading-spinner-small"></div>
      ) : (
        <>
          <h3>טופס {activeCase === 1 ? 'מכירת אקדח' : activeCase === 2 ? 'מכירת אקדח ותחמושת' :
           activeCase === 3 ? 'מכירת תחמושת' : activeCase === 4 ? 'אישור הדרכה במטווח' : activeCase === 5 ? 'רכישת אקדח' : activeCase === 6 ? 'מפעל ראוי' : 'אימון שיוך'}</h3>

          <div className="gun-ammo-train-details-col">{renderFormFields()}</div>
          {activeCase === 1 || activeCase === 2 || activeCase === 3 || activeCase === 5 ? (
            <div className="gun-ammo-train-details-col">
              <p>חתימת הלקוח:</p>
              <SignatureCanvas penColor="#3c6eb4" ref={sigCanvas} canvasProps={{ className: 'gun-ammo-train-sigCanvas' }} />
              <button className="gun-ammo-train-show-button" style={{backgroundColor:"brown"}} onClick={clearSignature}>
                נקה חתימה
              </button>
            </div>
          ) : null}
          <div className="gun-ammo-train-buttons">
            <button className="gun-ammo-train-show-button" onClick={handleGenerate}>
              צור מסמך
            </button>
            <button className="gun-ammo-train-show-button" onClick={() => setActiveCase(null)}>
              ביטול
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default GunAmmoTrain;
